import React, {useState, useRef, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import classNames from 'classnames';
import {FlexRowFullCenterFixedNav} from '../layout/layout';
import {useTranslation} from 'react-i18next';
import DropdownLanguages from "../dropdown-languages/DropdownLanguages";
import {MobileNavLinks} from "./MobileNavLinks";
import useScrollPosition from "../../hooks/useScrollPosition";
import useClickOutside from '../../hooks/useClickOutside';
import {
    Data,
    getPropertiesOperations,
    PropertyOperationAttributes
} from "../../services/dataService";

interface HeaderProps {
  hidden?: boolean,
}

interface FormDataElement {
    key: string;
    value: string;
}

const convertPropertyOperations = (list: Data<PropertyOperationAttributes>[], currentLang: string, t:any): FormDataElement[] => {
    const result = list.filter(propertyOperation => {
        const slug = propertyOperation.attributes.slug.toLowerCase();
        return !slug.includes("sale");
    }).map(propertyOperation => {
        let translatedValue = null;

        for (const translation of propertyOperation.attributes.translations) {
            if (translation.language === currentLang) {
                translatedValue = translation.value;
                break;
            }
        }

        if (!translatedValue) {
            translatedValue = propertyOperation.attributes.slug;
        }

        return {
            key: propertyOperation.attributes.slug,
            value: translatedValue,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [...result];
}

const ClassLi = ({children}: React.PropsWithChildren<any>) => {
  return (
    <li className="uppercase font-medium font-menu text-white px-4 py-3 flex items-center transition duration-150 ease-in-out relative">
      {children}
    </li>
  );
}

const Header = (props: HeaderProps) => {
  const { t, i18n } = useTranslation();
  const scrollPosition = useScrollPosition();
  const [openSubNav, setOpenSubNav] = useState(false);
  const [propertyOperationList, setPropertyOperationList] = useState<Data<PropertyOperationAttributes>[]>([]);
  const [propertyOperationFormData, setPropertyOperationFormData] = useState<FormDataElement[]>([]);
  const containerRef = useRef(null);

    useClickOutside(containerRef, () => setOpenSubNav(false));

    useEffect(() => {
        getPropertiesOperations()
            .then(setPropertyOperationList);
    }, []);

    useEffect(() => {
        Promise.resolve(propertyOperationList)
            .then(list => convertPropertyOperations(list, i18n.resolvedLanguage, t))
            .then(setPropertyOperationFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.resolvedLanguage, propertyOperationList]);

    const toggleNav = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setOpenSubNav(!openSubNav);
    };

  return (
    <FlexRowFullCenterFixedNav {...props} >
        <div className={classNames('max-w-6xl', 'mx-auto px-5', 'sm:px-6')}>
            <div className="flex items-center justify-between h-16 md:h-20 lg:h-28 py-2">
                <div className="flex-shrink-0 mr-4 z-50">
                    <Link to="/" className={classNames('block', 'w-10 md:w-14 lg:w-20')}>
                        <img src={`${process.env.PUBLIC_URL}/images/reesman_logo.png`}/>
                    </Link>
                </div>
                <div className={classNames('hidden', 'xl:block', 'flex', 'flex-grow', 'animate-logo')}>
                    <ul className="flex flex-grow justify-end flex-wrap items-center">
                        <ClassLi>
                            <Link to={`/${i18n.resolvedLanguage}`}>{t('nav-items.home')}</Link>
                        </ClassLi>
                        <ClassLi>
                            <Link
                                to={`/${i18n.resolvedLanguage}/properties?propertyOperation=sale`}>{t('nav-items.sale')}</Link>
                        </ClassLi>
                        <ClassLi>
                            <button ref={containerRef} onClick={toggleNav} className="uppercase flex items-center gap-x-1">
                                {t('nav-items.rent')}

                                <div className={`motion-safe:transition-transform ${openSubNav ? 'scale-y-[-1]' : ''}`}>
                                    <svg strokeWidth="24" viewBox="0 0 140 140" className="w-2 ml-1 overflow-visible stroke-current text-white">
                                        <path d="M135.625 42.648l-62.533 62.527a4.37 4.37 0 01-6.184 0L4.375 42.648"
                                              fill="none"/>
                                    </svg>
                                </div>
                            </button>

                            {openSubNav && (
                                <ul className="absolute right-0 mt-52 -ml-3 py-2 px-3 bg-white border border-neutral/10 rounded shadow">
                                    {propertyOperationFormData.map(element => (
                                        <li key={element.key}>
                                          <Link to={`/${i18n.resolvedLanguage}/properties?propertyOperation=${element.key}`} className="whitespace-nowrap py-2 px-1 -mx-1 block text-xs font-bold text-gray-700 focus:outline-none focus-visible:ring-2 ring-primary">
                                              {element.value}
                                          </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                      </ClassLi>
                      <ClassLi>
                          <Link to={`/${i18n.resolvedLanguage}/register-property`}>{t('nav-items.register')}</Link>
                      </ClassLi>
                      <ClassLi>
                          <Link to={`/${i18n.resolvedLanguage}/contact`}>{t('nav-items.contact')}</Link>
                      </ClassLi>
                      <ClassLi>
                          <DropdownLanguages/>
                      </ClassLi>
                      <li className={classNames('uppercase', 'font-medium', 'font-menu', 'text-white', 'px-3', 'py-3', 'flex', 'items-center', 'transition', 'duration-150', 'ease-in-out')}>
                          <a href="https://m.facebook.com/reesmanasesores/" target="_blank">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-facebook">
                                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                              </svg>
                          </a>
                      </li>
                      <li className={classNames('uppercase', 'font-medium', 'font-menu', 'text-white', 'px-3', 'py-3', 'flex', 'items-center', 'transition', 'duration-150', 'ease-in-out')}>
                          <a href="https://www.instagram.com/reesman_inmobiliaria/" target="_blank">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-instagram">
                                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                              </svg>
                          </a>
                      </li>
                      <li className={classNames('uppercase', 'font-medium', 'font-menu', 'text-white', 'px-3', 'py-3', 'flex', 'items-center', 'transition', 'duration-150', 'ease-in-out')}>
                          <a href="https://www.youtube.com/@reesman_inmobiliaria" target="_blank">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                   strokeLinejoin="round" className="feather feather-youtube">
                                  <path
                                      d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                                  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                              </svg>
                          </a>
                      </li>
                      <li className={classNames('uppercase', 'font-medium', 'font-menu', 'text-white', 'px-3', 'py-3', 'flex', 'items-center', 'transition', 'duration-150', 'ease-in-out', {
                          'xl:block': scrollPosition > 10,
                          'hidden': scrollPosition < 10
                      })}>
                          <a href="#search-properties">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                   viewBox="0 0 24 24"
                                   stroke="currentColor" strokeWidth="2">
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                              </svg>
                          </a>
                      </li>
                  </ul>
              </div>
                <div className="-mr-2 flex items-center xl:hidden">
                    <MobileNavLinks/>
                </div>
            </div>
        </div>
    </FlexRowFullCenterFixedNav>
  );
}

export default Header;
