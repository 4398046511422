import React, {useEffect, useState, useRef} from "react";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import styled from "styled-components";
import { motion } from 'framer-motion';
import { MenuToggle } from "./MenuToggle";
import {useTranslation} from "react-i18next";
import {getSupportedLanguages} from "../../configuration/i18n/i18n";
import classNames from "classnames";
import {Data, getPropertiesOperations, PropertyOperationAttributes} from "../../services/dataService";
import useClickOutside from "../../hooks/useClickOutside";

const NavLinksContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const LinksWrapper = styled(motion.ul)`
  margin: 0;
  padding: 10px;
  display: flex;
  height: 100vh;
  list-style: none;
  background-color: #000;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  overflow: auto;
`;

const LinkItem = styled(motion.li)`
  width: 100%;
  padding: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
`;

const menuVariants = {
    open: {
        transform: "translateY(0%)"
    },
    closed: {
        transform: "translateY(-100%)"
    }
}

const variantsLi = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

interface FormDataElement {
    key: string;
    value: string;
}

const convertPropertyOperation = (list: Data<PropertyOperationAttributes>[], currentLang: string, t:any): FormDataElement[] => {
    const result = list.filter(propertyOperation => {
        const slug = propertyOperation.attributes.slug.toLowerCase();
        return !slug.includes("sale");
    }).map(propertyOperation => {
        let translatedValue = null;

        for (const translation of propertyOperation.attributes.translations) {
            if (translation.language === currentLang) {
                translatedValue = translation.value;
                break;
            }
        }

        if (!translatedValue) {
            translatedValue = propertyOperation.attributes.slug;
        }

        return {
            key: propertyOperation.attributes.slug,
            value: translatedValue,
        };
    });

    result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

    return [...result];
}

const menuTransition = { type: "spring", duration: 0.2, stiffness: 400, damping: 40, delay: 0.3 }

export function MobileNavLinks(props:any) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const [showLanguages, setLanguages] = useState(false);
    const [languages] = useState<string[]>(getSupportedLanguages());
    const [openSubNav, setOpenSubNav] = useState(false);
    const [propertyOperationList, setPropertyOperationList] = useState<Data<PropertyOperationAttributes>[]>([]);
    const [propertyOperationFormData, setPropertyOperationFormData] = useState<FormDataElement[]>([]);
    const containerRef = useRef(null);

    useClickOutside(containerRef, () => setOpenSubNav(false));

    useEffect(() => {
        getPropertiesOperations()
            .then(setPropertyOperationList);
    }, []);

    useEffect(() => {
        Promise.resolve(propertyOperationList)
            .then(list => convertPropertyOperation(list, i18n.resolvedLanguage, t))
            .then(setPropertyOperationFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.resolvedLanguage, propertyOperationList]);

    const toggleNav = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setOpenSubNav(!openSubNav);
    };
    const handleClick = () => {
        setLanguages(!showLanguages);
    }
    const onClickLanguage = (language: string) => {
        const currentLanguage = i18n.resolvedLanguage;
        i18n.changeLanguage(language).then(() => {
            navigate(location.pathname.replace(currentLanguage, language));
            handleClick();
        });
        setOpen(!isOpen)
    }

    useEffect(() => {
        (isOpen) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
    });

    return (
        <NavLinksContainer>
            <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
            <LinksWrapper initial={false} animate={isOpen ? 'open' : 'closed'} variants={menuVariants}
                          transition={menuTransition}>
                <LinkItem variants={variantsLi} whileHover={{scale: 1.1}}>
                    <Link to={`/${i18n.resolvedLanguage}`} onClick={() => setOpen(!isOpen)}>{t('nav-items.home')}</Link>
                </LinkItem>
                <LinkItem variants={variantsLi} whileHover={{scale: 1.1}}>
                    <Link to={`/${i18n.resolvedLanguage}/properties?propertyOperation=sale`}
                          onClick={() => setOpen(!isOpen)}>{t('nav-items.sale')}</Link>
                </LinkItem>
                <LinkItem variants={variantsLi} whileHover={{scale: 1.1}}>
                    <button ref={containerRef} onClick={toggleNav} className="flex justify-center items-center gap-x-1">
                        {t('nav-items.rent')}

                        <div className={`motion-safe:transition-transform ${openSubNav ? 'scale-y-[-1]' : ''}`}>
                            <svg strokeWidth="24" viewBox="0 0 140 140" className="w-2 ml-1 overflow-visible stroke-current text-white">
                                <path d="M135.625 42.648l-62.533 62.527a4.37 4.37 0 01-6.184 0L4.375 42.648"
                                      fill="none"/>
                            </svg>
                        </div>
                    </button>

                    {openSubNav && (
                        <ul className="flex flex-col items-stretch space-y-2">
                            {propertyOperationFormData.map(element => (
                                <li>
                                    <Link to={`/${i18n.resolvedLanguage}/properties?propertyOperation=${element.key}`} onClick={() => setOpen(!isOpen)} className="whitespace-nowrap py-2 px-1 block text-base text-white focus:outline-none focus-visible:ring-2 ring-primary">
                                        {element.value}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    )}
                </LinkItem>
                <LinkItem variants={variantsLi} whileHover={{scale: 1.1}}>
                    <Link to={`/${i18n.resolvedLanguage}/register-property`}
                          onClick={() => setOpen(!isOpen)}>{t('nav-items.register')}</Link>
                </LinkItem>
                <LinkItem variants={variantsLi} whileHover={{scale: 1.1}}>
                    <Link to={`/${i18n.resolvedLanguage}/contact`}
                          onClick={() => setOpen(!isOpen)}>{t('nav-items.contact')}</Link>
                </LinkItem>
                <LinkItem variants={variantsLi} whileHover={{scale: 1.1}}>
                    {languages && languages.map(language => {
                        if (i18n.resolvedLanguage !== language) {
                            return <span key={language} onClick={() => onClickLanguage(language)}
                                         className="flex flew-row justify-center items-center text-white px-4 py-2 cursor-pointer"
                                         role="menuitem" id="menu-item-0">
                                    <img className="h-6 w-6 mr-2"
                                         src={`${process.env.PUBLIC_URL}/images/flags/flag_${language === 'es' ? 'es' : 'en'}.png`}
                                         alt={`${language === 'es' ? 'español' : 'english'}`}/>{language.toUpperCase()}
                                </span>;
                        }
                    })}
                </LinkItem>
                <LinkItem variants={variantsLi} whileHover={{scale: 1.1}}>
                    <div className="flex flex-row justify-center items-center gap-x-6">
                        <a href="https://m.facebook.com/reesmanasesores/" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-facebook">
                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/reesman_inmobiliaria/" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-instagram">
                                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                            </svg>
                        </a>
                        <a href="https://www.youtube.com/@reesman_inmobiliaria" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-youtube">
                                <path
                                    d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                                <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                            </svg>
                        </a>
                    </div>
                </LinkItem>
            </LinksWrapper>
        </NavLinksContainer>
    );
}
