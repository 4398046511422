import React from 'react';
import {useTranslation} from 'react-i18next';

const Whatsapp = () => {
  const { t } = useTranslation();

  return (
      <a
          href="https://wa.me/+34624294216"
          target="_blank"
          aria-label={t('contact-section.whatsapp')}
          className="fixed bottom-14 right-0 z-50 w-12 h-12 mr-4 md:mr-8 text-xs font-bold text-white"
      >
          <svg viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="36" cy="36" r="36" fill="#25D366"/><path fillRule="evenodd" clipRule="evenodd" d="M50.148 21.6c-3.79-3.79-8.843-5.81-14.148-5.81-11.116 0-20.21 9.094-20.21 20.21 0 3.537 1.01 7.074 2.779 10.105l-2.78 10.106L26.4 53.43c3.032 1.516 6.316 2.527 9.6 2.527 11.116 0 20.21-9.095 20.21-20.21 0-5.306-2.273-10.358-6.063-14.148ZM36 52.674c-3.032 0-6.063-.758-8.59-2.274l-.505-.253-6.316 1.769 1.769-6.063-.505-.758c-1.769-2.78-2.527-5.81-2.527-8.842 0-9.095 7.58-16.674 16.674-16.674 4.547 0 8.59 1.768 11.874 4.8 3.284 3.284 4.8 7.326 4.8 11.874 0 8.842-7.327 16.42-16.674 16.42Zm9.095-12.632c-.505-.253-3.032-1.516-3.537-1.516-.505-.252-.758-.252-1.01.253-.253.505-1.264 1.516-1.516 2.021-.253.253-.506.253-1.01.253-.506-.253-2.022-.758-4.043-2.527-1.516-1.263-2.526-3.031-2.779-3.537-.252-.505 0-.757.253-1.01l.758-.758c.252-.253.252-.505.505-.758.252-.252 0-.505 0-.758 0-.252-1.01-2.779-1.516-3.79-.252-.757-.758-.757-1.01-.757h-1.011c-.253 0-.758 0-1.263.505-.505.505-1.768 1.769-1.768 4.295s1.768 4.8 2.02 5.305c.253.253 3.537 5.558 8.59 7.58 4.295 1.767 5.053 1.262 6.063 1.262 1.01 0 3.032-1.263 3.284-2.273.506-1.264.506-2.274.253-2.274-.253-1.263-.758-1.263-1.263-1.516Z" fill="#fff"/></svg>
      </a>
  );
}

export default Whatsapp;
